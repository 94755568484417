import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { selectAppData, selectGroupFetchStatus } from 'store/selectors';
import { useController } from 'common/context/controller';
import { useDidUpdate } from 'common/hooks';

import { Box } from 'wui/Box';
import { Spinner } from 'wui/Spinner';
import { EmptyState } from 'wui/EmptyState';

import { GroupDetails } from 'Group/Widget/Header/GroupDetails';
import { Feed } from './Feed';

import settingsParams from 'FeedWidget/settingsParams';
import { FEED_WIDGET_WRAPPER } from './dataHooks';

export const Widget = () => {
  const { t } = useTranslation();
  const settings = useSettings();

  const { groupId } = useSelector(selectAppData);
  const { group$ } = useController();
  const { loading } = useSelector(
    selectGroupFetchStatus(settings.get(settingsParams.groupId)),
  );

  useDidUpdate(() => {
    group$.fetch(groupId);
  }, [groupId]);

  if (loading) {
    return <EmptyState title={<Spinner />} />;
  }

  if (!groupId) {
    return (
      <EmptyState
        title={t('groups-web.feed-widget.empty.title')}
        subtitle={t('groups-web.feed-widget.empty.description')}
      />
    );
  }

  return (
    <Box gap="SP5" direction="vertical" data-hook={FEED_WIDGET_WRAPPER}>
      <GroupDetails groupId={groupId} />
      <Feed />
    </Box>
  );
};

Widget.displayName = 'Widget';
