import React, { useMemo } from 'react';
import * as yup from 'yup';
import { FieldArray, Formik, FormikProps } from 'formik';
import { useTranslation } from '@wix/yoshi-flow-editor';
import type { MembershipQuestion } from '@wix/ambassador-social-groups-v2-membership-question/types';

import { Box } from 'wui/Box';
import { TextField } from 'wui/TextField';
import { TextButton } from 'wui/TextButton';
import { Checkbox } from 'wui/Checkbox';
import { Delete as DeleteIcon } from '@wix/wix-ui-icons-common/on-stage';
import { IconButton } from 'wui/IconButton';
import { Stack } from 'wui/Stack';
import { Divider } from 'wui/Divider';

import { MEMBERSHIP_QUESTION_ADD } from './dataHooks';

export interface IGroupQuestionsForm {
  questions: MembershipQuestion[];
}

interface IProps
  extends React.ComponentProps<typeof Formik<IGroupQuestionsForm>> {}

export const GroupQuestionsForm = React.forwardRef(
  // eslint-disable-next-line react/display-name
  (props: IProps, ref: React.Ref<FormikProps<IGroupQuestionsForm>>) => {
    const { ...rest } = props;

    const { t } = useTranslation();

    const validationSchema = useMemo(
      () =>
        yup.object().shape({
          questions: yup.array().of(
            yup.object().shape({
              text: yup
                .string()
                .trim()
                .required(
                  t('groups-web.membership-questions.question.required.error'),
                ),
            }),
          ),
        }),
      [],
    );

    return (
      <Formik
        enableReinitialize
        innerRef={ref}
        validationSchema={validationSchema}
        {...rest}
      >
        {(form) => (
          <FieldArray name="questions">
            {(questions) => (
              <Stack direction="vertical" gap="SP4" separator={<Divider bw />}>
                {form.values.questions.map((question, index) => {
                  const meta = form.getFieldMeta(`questions.${index}.text`);

                  return (
                    <Box direction="vertical" gap="SP1">
                      <TextField
                        key={index}
                        autoFocus={!meta.initialValue}
                        label={getQuestionLabel(index)}
                        errorMessage={meta.error}
                        error={meta.touched && !!meta.error}
                        placeholder={t(
                          'groups-web.membership-questions.question.placeholder',
                        )}
                        suffix={
                          <IconButton
                            icon={<DeleteIcon />}
                            onClick={() => questions.remove(index)}
                          />
                        }
                        {...form.getFieldProps(`questions.${index}.text`)}
                      />
                      <Checkbox
                        label={t(
                          'groups-web.membership-questions.question.required.label',
                        )}
                        {...form.getFieldMeta(`questions.${index}.required`)}
                        {...form.getFieldProps({
                          type: 'checkbox',
                          name: `questions.${index}.required`,
                        })}
                      />
                    </Box>
                  );
                })}
                <TextButton
                  data-hook={MEMBERSHIP_QUESTION_ADD}
                  onClick={() =>
                    questions.push({
                      text: '',
                      required: false,
                    })
                  }
                >
                  + {t('groups-web.membership-questions.add')}
                </TextButton>
              </Stack>
            )}
          </FieldArray>
        )}
      </Formik>
    );

    function getQuestionLabel(index: number) {
      return `${t('groups-web.membership-questions.question')} ${index + 1}`;
    }
  },
);

GroupQuestionsForm.displayName = 'GroupQuestionsForm';
