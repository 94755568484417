import type { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

import { getDisplayForCssVar } from 'common/utils/getDisplayForCssVar';

// noinspection JSUnusedGlobalSymbols
export const customCssVars: CustomCssVarsFn = ({ styleParams }) => {
  return {
    showReactionsBlock: getDisplayForCssVar(
      styleParams,
      'showReactions',
      'block',
    ),
    showCommentsBlock: getDisplayForCssVar(
      styleParams,
      'showComments',
      'block',
    ),
  };
};
